@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

* {
  box-sizing: border-box;
}

::placeholder {
  color: lightblue;
  opacity: 1;
}

body {
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  margin: 0;
  color: darkblue;
}

.header {
  margin: 0;
  background-image: linear-gradient(to right, darkblue, dodgerblue, lightblue);
  background-color: aliceblue;
  width: 100%;
  height: 80px;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 1em;
}

.header h1 {
  text-align: left;
  font-size: 2rem;
  color: aliceblue;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0;
}
 
.header .search {
  display: flex;
  flex-direction: row;
}

h2 {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 1.8rem;
}

h3 {
  color: dodgerblue;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
}

h4 {
  text-align: center;
}

.msg {
  text-align: center;
}

img {
  width: 100px;
  height: 100px;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 100px;
}

.location-btn {
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 30px;
  color: aliceblue;
  font-size: 30px;
  transition: .5s;
}

.location-btn:hover {
  color: darkblue;
}

.search-input {
  background-color: aliceblue;
  border: 2px solid aliceblue;
  border-radius: 30px;
  padding: 6px;
  width: 320px;
  color: darkblue;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  transition: .5s;
}

.search-input:hover,
.search-input:active,
.search-input:focus {
  border: 2px solid darkblue;
  outline: none;
}

a {
  color: dodgerblue;
  transition: .5s;
}

a:hover,
a:focus {
  color: darkblue;
}

.weather {
  text-align: left;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3em;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.box {
  background-color: aliceblue;
  width: 350px;
  text-align: center;
  border-radius: 5px;
  padding: 0;
}

.box img {
  margin: 0;
}

.forecast-hourly {
  width: 50%;
  margin: 0 auto;
  text-align: left;
  background-color: white;
}

.forecast-hourly img {
  width: 30px;
  height: 30px;
  float: right;
}

.forecast-hourly div:nth-child(even) {
  background-color: white;
  padding: 1em;
  border-radius: 5px;
}

.forecast-hourly div:nth-child(odd) {
  background-color: aliceblue;
  padding: 1em;
  border-radius: 5px;
}

.forecast-daily {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto ;
  grid-gap: .2em;
  padding: .5em;
  margin-bottom: 1em;
  margin-left: 1em;
}

.forecast-daily > div {
  width: 140px;
  height: 230px;
  background-color: aliceblue;
  text-align: center;
  font-size: 1rem;
  padding: .5em;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.forecast-daily img {
  float: bottom;
}

@media (max-width: 800px){
  .header {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 150px;
  }
  .container {
    margin-top: 180px;
  }
  .weather {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .h2 {
    padding: 0;
    margin: 0;
  }
  .weather {
    width: 100%;
  }
  .description {
    margin-top: 0;
  }
  .forecast-daily {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .forecast-daily > div {
    width: 200px;
  }
  .forecast-hourly {
    width: 90%;
  }
  .forecast-hourly img {
    display: none;
  }
}